
.card {
    flex: 1;
    background-color: var(--main-color);
    color: var(--text-color);
    padding: 20px;
    transition: 0.3s ease;
    border-radius: 0.5em;;
    backdrop-filter: blur(10px);
    background: rgba(var(--main-color), 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.card h1 {
    font-size: 1.2em;
    margin : 0;
    text-align: left;
}

.card-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin:0;
}