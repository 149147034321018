.application img {
  width: 6em;
  height: 6em;
  border-radius: 5px;
  object-fit: cover;
}

.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  width: 10em;
  height: 12em;

  transition: 0.3s ease;
  border-radius: 0.5em;;
  backdrop-filter: blur(10px);
  background: rgba(var(--main-color), 0.5);
  color: var(--text-color);

  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.application:hover {
  transform: translateY(-5px);
}

.application:active {
  filter: brightness(0.8);
}

.appLink {
  text-decoration: none;
  color: var(--text-color);
}

.application h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  font-weight:600;
  font-size: 1.2em;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .application {
    width: 8em;
    height: 10em;
  }

  .application img {
    width: 5em;
    height: 5em;
  }

  .application h2 {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .application {
    width: 6em;
    height: 8em;
  }

  .application img {
    width: 4em;
    height: 4em;
  }
  .application h2 {
    font-size: 0.8em;
  }
}