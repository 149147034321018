.authentication {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    
    background-color: var(--main-color);
    color: var(--text-color);
    padding: 20px;
    transition: 0.3s ease;
    border-radius: 0.5em;;
    backdrop-filter: blur(10px);
    background: rgba(var(--main-color), 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

    transition: height 0.3s ease !important;
    width:100%;
    box-sizing: border-box;
}

.auth-buttons {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.auth-buttons button {
    margin: 0;
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    color: var(--text-color);
    border: none;
    width: 50%;
    position: relative;
}

.auth-buttons button:hover.inactive {
    opacity: 0.8;
}

.auth-buttons .active {
    font-weight: bold;
}

.auth-buttons .active.login::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--text-color);
    animation: slide-in-left 0.3s ease forwards;
}

.auth-buttons .active.register::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: var(--text-color);
    animation: slide-in-right 0.3s ease forwards;
}

.auth-buttons .inactive.login::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--text-color);
    animation: slide-out-right 0.3s ease forwards;
}

.auth-buttons .inactive.register::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: var(--text-color);
    animation: slide-out-right 0.3s ease forwards;
}

.fade-enter {
    opacity: 0;
    transform: translateX(-10px);
    position: absolute;
    width: 100%;
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
    transform: translateX(0);
    position: absolute;
    width: 100%;
}
.fade-exit-active {
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 300ms, transform 300ms;
}

.auth-content {
    position: relative;
    transition: height 0.3s ease !important;
}

@keyframes slide-in-left {
    from {
        width: 0;
        left: 0;
    }
    to {
        width: 100%;
        left: 0;
    }
}

@keyframes slide-in-right {
    from {
        width: 0;
        right: 0;
    }
    to {
        width: 100%;
        right: 0;
    }
}

@keyframes slide-out-left {
    from {
        width: 100%;
        left: 0;
    }
    to {
        width: 0;
        left: 0;
    }
}

@keyframes slide-out-right {
    from {
        width: 100%;
        right: 0;
    }
    to {
        width: 0;
        right: 0;
    }
}






