.sidebar {
  position: fixed;
  top: 0;
  left: -350px;
  /* Start hidden off-screen */
  width: 250px;
  height: 100%;
  background-color: var(--main-color);
  color:var(--text-color);
  transition: left 0.5s ease;
  /* Smooth transition */
  z-index: 1001;
  /* Ensure it appears above everything */
  padding: 20px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  left: 0;
  /* Move in when open */
}

.sidebar .close-btn {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar ul li {
  width: 100%;
  transition: 0.3s ease;
  padding: 10px;
  margin: 0;
  border-radius: 5px;
}

.sidebar ul li:hover {
  background-color: rgba(var(--text-color), 0.2);

}

.sidebar a {
  color: var(--text-color);
  text-decoration: none;
  transition: 0.3s ease;
}



.sidebar a:hover {
  opacity: 0.7;
}