.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #e0e0df;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: var(--vivid-color);
    width: 0;
    transition: width 0.5s ease-in-out;
    border-radius: 5px 0 0 5px;
    text-align: center;
  }
  