.login {
    display: flex;
    flex-direction: column;
}

.login input {
    margin: 10px 0;
    padding: 10px;
    border-radius: 0.5em;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    color: var(--text-color);
}

.login label {
    text-align: left;
}

.login button {
    color: var(--main-color);
    margin: 0 auto;
    margin-top: 20px;
    padding: 5px 20px;
    width: 50%;
    cursor: pointer;
    background-color: var(--text-color);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease;
}

.login button:hover {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    background: none;
}