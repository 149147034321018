
:root{
  --main-color :rgb(245,245,245);
  --text-color : rgb(25,25,25);
  --vivid-color : rgb(187, 58, 73);
}

[data-theme="dark"] {
  --main-color: rgb(26, 26, 26);
  --text-color : rgb(245,245,245);
  --vivid-color : rgb(58, 187, 148);
}


.app {
  display: flex;
  height: 100vh;

  transition: all ease-out 1s;
}

body {
  background-color: var(--main-color);
  color: var(--text-color);
}

.main-content{
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease;
  padding-top: 5em;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 1400px ;
  margin: 0 auto;
}