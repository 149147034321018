@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--main-color);
    color: var(--text-color);
    padding: 0.5em 1em 0.5em 1em;

    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    height: 4em;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.header a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.header .title,
.header .logo {
  color: var(--text-color);
  user-select: none; /* Empêche la sélection du texte et du logo */
}

.logo.light{
  filter : invert(100);
}

.header.hidden {
    transform: translateY(-100%); /* Cache le header en le déplaçant vers le haut */
  }
  
  .header.visible {
    transform: translateY(0); /* Affiche le header à sa position normale */
  }

.header h1{
  flex: 1;
  font-size: larger;
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}


.toggle-sidebar-btn {
  font-size: 1.5rem;
  
  margin-right: 20px;

  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition:  0.5s ease;
}

.toggle-sidebar-btn.rotated {
  transform: rotate(90deg); /* Rotation de 90 degrés */
}

.toggle-sidebar-btn:hover {
  opacity: 0.7;
}

.logo {
  width: 2em; /* Ajustez la largeur selon vos besoins */
  height: auto; /* Maintient le ratio d'aspect */
  margin:1em;
}

.title{
  display: flex;
  align-items: center;
  height: 100%;
}

.left-header {
  display: flex;
  align-items: center;
}

.right-header {

  align-self: right;
  display: flex;
  align-items: center;
}

.profile {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1em;
  z-index: 10000;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}