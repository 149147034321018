.account{
  background-color: var(--main-color);
  color: var(--text-color);
  padding: 20px;
  transition: 0.3s ease;
  border-radius: 0.5em;;
  backdrop-filter: blur(10px);
  background: rgba(var(--main-color), 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 700px;
}
.account-title{
  display: flex;
  align-items: center;
  gap:1em;
}

.btn{
  padding: 10px;
  border-radius: 0.5em;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.btn-primary{
  background-color: var(--vivid-color);
  color: var(--text-color);
}


.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
width: 100%;
}


.account-header div.profile {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    margin-left:0;
    overflow: hidden;
    z-index: 10000;

  }
  
  .account-header  div.profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    word-wrap: break-word;
  
  }

  .profile-info h2{
    font-size: 1.5em;
    margin: 0;
  }