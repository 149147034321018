.network-device{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 10px;


    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    background-color: var(--main-color);
}

.network-device h2 {
    text-align: left;
    margin: 0;
}

.network-device .informations {
    display: flex;
    flex-direction: column;
}

.network-device .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-indicator.online {
    background-color: green;
}

.status-indicator.offline {
    background-color: red;
}

.addresses {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
    
}