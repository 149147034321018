.device-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.device-popup {
    width: 400px;
    background: var(--main-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 10000;
}

.device-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.device-popup-title {
    font-size: 18px;
    font-weight: bold;
}

.device-popup-close {
    cursor: pointer;
    font-size: 20px;
}

.device-popup-content {
    font-size: 14px;
}

.device-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.device-popup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.device-popup-button:hover {
    background-color: #0056b3;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}