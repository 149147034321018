.notification-container {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  
  .notification {
    background-color: var(--main-color);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .notification.info {
    border-left: 4px solid #007bff;
  }
  
  .notification.success {
    border-left: 4px solid #28a745;
  }
  
  .notification.error {
    border-left: 4px solid #dc3545;
  }
  
  .notification button {
    background: none;
    border: none;
    color: #555;
    font-size: 14px;
    cursor: pointer;
  }
  