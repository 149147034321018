.cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 calc(33.333% - 20px); /* 33.333% pour 3 cartes par ligne, moins l'espace du gap */
  box-sizing: border-box; /* Assure que le padding et la bordure sont inclus dans la largeur totale */
}

@media (max-width: 900px) {
  .card {
    flex: 1 1 calc(50% - 20px); /* 50% pour 2 cartes par ligne sur les écrans plus petits */
  }
}

@media (max-width: 600px) {
  .card {
    flex: 1 1 100%; /* 100% pour 1 carte par ligne sur les écrans très petits */
  }
}

.vued {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--text-color);
  font-weight: 900;

  transition: background 0.2s;
}

.addButton:hover {
  background: rgba(255, 255, 255, 0.3);
}