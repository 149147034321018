.AppsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start; /* Par défaut, aligner à gauche */
    margin-top: 20px;
    gap: 20px;

    padding: 1em;
    transition: 0.3s ease;
    border-radius: 0.5em;
    backdrop-filter: blur(10px);
    background: rgba(var(--main-color), 0.5);
    color: var(--text-color);

    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.AppsContainer.space-between {
    justify-content: space-between; /* Équilibre les éléments horizontalement */
}
