ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    width: 10em;
}

/* ProgressBar.css */

p {
    margin: 0;
    padding: 5px 0 ;
}