.network-devices {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
}
.filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    gap: 10px;
}

.network-devices-container{
    width: auto;
}

.filter {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--text-color);
}

.filter.connected {
    background-color: rgb(140, 255, 140);
}

.filter.disconnected {
    background-color: rgb(255, 121, 121);
}

.filter.all {
    background-color: rgba(0, 0, 0, 0);
}