/* Conteneur principal du menu déroulant */
.top-drawer {
  position: fixed;
  top: -100%; /* Commence en bas, hors de l'écran */
float:right;
right:0;
  width: auto;
  margin: 0;
  height: auto; /* Hauteur du menu, ajustez selon vos besoins */
  background-color: var(--main-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  transition: top 0.3s ease-in-out; /* Animation de glissement */
  z-index: 1001;
  border-radius: 10px 10px 0 0; /* Coins arrondis en haut */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Ombre sous le menu */
}

/* Lorsque le menu est ouvert */
.top-drawer.open {
  top: 0; /* Le menu apparaît */
}

/* Bouton pour fermer le menu */
.close-button {
  background: none;
  border: none;
  color: var(--text-color);
  align-self: flex-end;
  cursor: pointer;
}

/* Contenu du menu */
.drawer-content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.drawer-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawer-content a {
  color: var(--text-color);
  text-decoration: none;
  width: 100%;
}

.drawer-content li {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  width: auto;
  text-align: center;
  
}

.drawer-content li:hover {
  opacity: 0.7;
  background-color: rgba(0,0,0,0.1);
  border-radius: 5px;
}

.account{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap:1em;
}

.item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid color-mix(in srgb, var(--text-color) 50%, transparent);
  gap : 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.top-drawer div.profile{
  width: 2em;
  height: 2em;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1em;
  z-index: 10000;

}

.top-drawer img.profile{
    width: 100%;
  height: 100%;
  object-fit: cover;
}