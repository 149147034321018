/* Conteneur principal du menu déroulant */
.bottom-drawer {
  position: fixed;
  bottom: -100%; /* Commence en bas, hors de l'écran */
  right: 0;
  width: auto;
  margin: 0;
  height: 50%; /* Hauteur du menu, ajustez selon vos besoins */
  background-color: var(--main-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  transition: bottom 0.3s ease-in-out; /* Animation de glissement */
  z-index: 1000;
  border-radius: 10px 10px 0 0; /* Coins arrondis en haut */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Ombre sous le menu */
}

/* Lorsque le menu est ouvert */
.bottom-drawer.open {
  bottom: 0; /* Le menu apparaît */
}

/* Bouton pour fermer le menu */
.close-button {
  background: none;
  border: none;
  color: var(--text-color);
  align-self: flex-end;
  cursor: pointer;
}

/* Contenu du menu */
.drawer-content {
  text-align: center;
}

.drawer-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawer-content a {
  color: var(--text-color);
  text-decoration: none;
  width: 100%;
}

.drawer-content li {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  width: auto;
  text-align: center;
  
}

.drawer-content li:hover {
  opacity: 0.7;
  background-color: rgba(0,0,0,0.1);
  border-radius: 5px;
}
