/* Global style for the Particle Background */
.particle-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: var(--main-color);
    overflow: hidden;
    opacity: 0.5;
  } 

